
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const submitUserInfo = async (name, age, phone_number, marketing_consent) => {
  try {
    const response = await axios.post(
      `${API_URL}/user/api/userinfo/`,
      {
        name,
        age,
        phone_number,
        marketing_consent,
      }
    );
    return response.data;
  } catch (error) {
    console.error("서버 오류:", error);
    throw error;
  }
};
