import React, { useState } from "react";
import { submitUserInfo } from "../api/user"; 
import "./Home.css";

const Home = () => {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [marketing_consent, setMarketingConsent] = useState(false); // 선택 동의 상태 추가

  const formatPhoneNumber = (number) => {
    if (number.length === 11) {
      return `${number.slice(0, 3)}-${number.slice(3, 7)}-${number.slice(7)}`;
    }
    return number;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name) {
      alert("이름을 입력해주세요.");
    } else if (!age) {
      alert("연령대를 선택해주세요.");
    } else if (phone_number.length !== 11) {
      alert("연락처를 바르게 입력해주세요.");
    } else if (!isAgreed) {
      alert("개인정보 처리방침에 동의해야 합니다.");
    } else {
      try {
        const marketingAgreement = marketing_consent ? "true" : "false";
        const formattedPhoneNumber = formatPhoneNumber(phone_number);

        await submitUserInfo(name, age, formattedPhoneNumber, marketingAgreement);
        alert(`신청되었습니다.`);
        setName("");
        setAge("");
        setPhoneNumber("");
        setIsAgreed(false);
        setMarketingConsent(false);
      } catch (error) {
        alert("서버에 제출하는 중 오류가 발생했습니다.");
      }
    }
  };

  const isFormValid = name && age && phone_number && isAgreed;

  return (
    <div className="home-container">
      <div className="form">
        <h1 className="title-h">프로그램 무료 상담 신청</h1>
        <form onSubmit={handleSubmit}>
          <label>*이름</label>
          <div className="form-group">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <label>*연령대</label>
          <div className="form-group">
            <select
              value={age}
              onChange={(e) => setAge(e.target.value)}
              className="select-field"
              required
            >
              <option value="">선택</option>
              {["20대", "30대", "40대", "50대 이상"].map((age) => (
                <option key={age} value={age}>
                  {age}
                </option>
              ))}
            </select>
          </div>
          <label>*연락처</label>
          <div className="form-group">
            <input
              type="tel"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="input-field"
              placeholder="010xxxxxxxx" // 필드 문구 추가
            />
          </div>
          <div className="form-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isAgreed}
                onChange={() => setIsAgreed(!isAgreed)}
                required
                className="checkbox-input"
              />
              (필수) 개인정보 수집·이용 동의
            </label>
          </div>
          <div className="form-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={marketing_consent}
                onChange={() => setMarketingConsent(!marketing_consent)} // 별도의 상태 관리
                className="checkbox-input"
              />
              (선택) 홍보 및 마케팅 수집·이용 동의
            </label>
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={!isFormValid} // 필수 조건만 체크
          >
            신청하기
          </button>
        </form>
      </div>
    </div>
  );
};

export default Home;
